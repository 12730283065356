import styled, { css } from "styled-components";

export const FrameDashboard = styled.div<{
  isOccurrence?: boolean;
  hideDashboardCharts?: boolean;
  getTheme: "dark" | "light";
  isBoltTab?: boolean;
}>`
  width: 100%;
  height: ${(props) => (props.hideDashboardCharts ? "30%" : "0")} !important;
  display: flex;
  flex-wrap: nowrap;
  /* overflow-x: auto; */
  overflow-y: ${(props) => (props.hideDashboardCharts ? "none" : "hidden")};
  justify-content: flex-start;
  gap: 20px;
  position: relative;
  transition: 0.3s;
  scrollbar-width: none;

  @media screen and (max-width: 767px) {
    margin-top: ${({ isOccurrence }) => (isOccurrence ? "0" : "3.125rem")};
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  @media screen and (min-width: 767px) and (max-width: 1280px) {
    display: visible;
    overflow-x: auto;
  }

  @media screen and (min-width: 767px) and (max-width: 1280px) {
    /* height: ${(props) =>
      props.hideDashboardCharts ? "20%" : "0"} !important; */
    &::before {
      content: "";
      right: 0;
      position: absolute;
      height: 100%;
      width: 3.5rem;
      background: ${({ getTheme }) =>
        getTheme === "dark"
          ? "linear-gradient(270deg, rgba(11, 13, 18, 1), rgba(11, 13, 18, 0))"
          : "linear-gradient(270deg, rgba(227, 232, 238, 1), rgba(227, 232, 238, 0))"};
      ${({ isBoltTab }) =>
        isBoltTab &&
        css`
          display: none;
        `}
      z-index: 2;
    }
  }

  //Edição do Slider do react-slick
  & > div {
    transition: 0.3s;

    & > div > div > div > div {
      margin: 0 4px;
    }
    .slick-slide {
      margin: 0 8px 0 0;
      height: unset;
      transition: 0.3s;

      overflow: hidden !important;

      @media screen and (min-width: 767px) and (max-width: 1536px) {
        min-width: 550px !important;
      }
    }

    .slick-slider {
      height: 100%;
    }

    .slick-track {
      display: flex;

      & > div > div {
        cursor: grab;

        &:active {
          cursor: grabbing;
        }

        height: 100%;
      }
    }

    .slick-dots {
      bottom: 5px;
      opacity: 0;
      padding-right: 12px;
      &:hover {
        opacity: 1;
      }
      li {
        width: 16px;
        height: 16px;
        button {
          border-radius: 50%;

          &::before {
            color: ${(props) => props.theme.colors.iconsPrimary};
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
    .slick-active {
    }

    .slick-list {
      height: 100%;
    }
  }
`;

export const HideDashboardButton = styled.button<{ expandDashboard?: boolean }>`
  width: fit-content;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.iconsSecondary};
  ${(props) => props.theme.fonts.primary.H4Regular};
  position: absolute;
  right: 3rem;
  opacity: 0.4;
  padding-top: 6px;

  span {
    margin-right: 6px;
  }
  svg {
    width: 1.25rem;
    height: auto;
    rotate: ${({ expandDashboard }) => (expandDashboard ? "180deg" : "0deg")};
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    border-radius: 6px;
    opacity: 1;
    scale: 1.05;
    transition: all 0.3s;
  }
`;

export const ExpandedDashboard = styled(HideDashboardButton)`
  right: 1rem;
  width: fit-content;
`;

export const FrameDashboardBox = styled.div`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  width: 100%;
  padding: 10px;

  @media (max-width: 767px) {
    min-width: 100%;
    height: 18.5rem;
    scroll-snap-align: start;
    /* overflow: hidden; */
  }
`;

export const ContainerMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  flex-direction: column;
`;

export const FrameDashboardBoxMobile = styled.div`
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem;
  width: 96%;

  & > div {
    padding: 0;
  }
  & > div {
    padding: 0;
  }
`;

export const ButtonSelect = styled.button<{ hideDashboardCharts?: boolean }>`
  background: red;
  z-index: 2;
  right: 0;
  position: absolute;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background: none;
  border: none;
  border-radius: 100%;
  padding: 5px;

  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.divBackgroundAlpha};

  :hover {
    background: ${(props) => props.theme.colors.iconsSecondaryAlpha};
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};

    transition: 0.3s;
  }
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;
