import theme from "../../styles/themes/default";

export interface IAssetInfo {
  key: string;
  value: string | number;
}
export interface IAsset {
  id: number;
  name: string;
  companyId: number;
  companyName: string;
  facilityId: number;
  facilityName: string;
  contract?: "PaaS" | "SOLaaS";
  sysStatusId: 1 | 2 | 3 | 4 | 5;
  theme?: any;
  operationStatus: "AVAILABLE" | "UNPLANNED_STOP" | "PLANNED_STOP";
  sysAssetTypeId?: string;
  lifetime?: string;
  problem?: string;
  model?: string;
  manufacturer?: string;
  sector?: string;
  role?: string;
  tag?: string;
  comments?: string;
  imageUrl?: string;
  info?: IAssetInfo[];
  logoUrl?: string;
  statusCaption?: string;
  rotation?: number | null;
  voltage?: number | null;
  powerFactor?: number | null;
  hourmeter?: boolean;
  url?: string;
  facilitySectorId?: number | null;
  mtbf?: number;
  mttr?: number;
  lastIntervention?: Date;
  risk?: number | null;
  monitoringTypes?: number[] | null;
  brokenOccurrenceId?: number | null;
  motorPower?: number;
  importantFieldPercentage?: number;
  sectorName?: string;
  tabs: any[];
  isReadOnly: boolean;
  deniedPermissions: any;
  isLightOffline?: boolean;
}

export const EMPTY_ASSET: IAsset = {
  id: 0,
  name: "",
  companyId: 0,
  companyName: "",
  facilityId: 0,
  facilityName: "",
  contract: "PaaS",
  sysStatusId: 1,
  operationStatus: "AVAILABLE",
  tabs: [],
  isReadOnly: false,
  deniedPermissions: {},
};

export const OPERATION_STATUS_COLOR: {
  PLANNED_STOP: string;
  UNPLANNED_STOP: string;
  AVAILABLE: string;
} = {
  PLANNED_STOP: theme.colors.statusAlert,
  UNPLANNED_STOP: theme.colors.statusRisk,
  AVAILABLE: theme.colors.statusNormal,
};
