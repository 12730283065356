import { FormInputContainer, LabelContainer } from "./styles";
import { TextInputProps } from "./types";
import { Field, FieldProps, useFormikContext } from "formik";
import { blockInvalidCharacter } from "../../utils/blockInvalidCharacter";
import InfoButton from "../../modals/PositionForm/InputRender/InfoButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { applyMaskByName } from "../../utils/inputMaskUtils";
import { LoadingInput } from "../LoadingInput";

export function FormikTextInput({
  edit,
  label,
  placeholder,
  labelDistance,
  name,
  error,
  noMessage,
  type,
  inputMode,
  onlyPositive,
  onlyInteger,
  disabled,
  handleChange,
  optional,
  important,
  height,
  loading,
  observation,
  isActivator,
}: TextInputProps) {
  const [value, setValue] = useState<any>();
  const {
    setFieldError,
    errors = {},
    setFieldValue,
  } = useFormikContext() as any;

  const { t } = useTranslation();

  const renderInput = (fieldProps: FieldProps, options?: any) => {
    const value = fieldProps.field.value;

    setValue(value);

    return (
      <input
        {...fieldProps.field}
        readOnly={edit}
        type={type ? type : "text"}
        placeholder={placeholder}
        inputMode={inputMode || "text"}
        disabled={disabled}
        onWheel={(e) => e.currentTarget.blur()}
        onKeyDown={(e) =>
          (type === "number" || options?.isActivator) &&
          blockInvalidCharacter(e, {
            positive: options?.onlyPositive,
            integer: options?.onlyInteger,
            activator: options?.isActivator,
            value,
          })
        }
        onChange={({ target }) => {
          const trimmedValue = target.value.trimStart();
          if (error) {
            setFieldError(name, "");
          }
          if (type === "number") {
            setFieldValue(target.name, handleNumeric(trimmedValue));
            return;
          }
          fieldProps.field.onChange(target.name)(
            handleChange
              ? handleChange(trimmedValue)
              : applyMaskByName(target.name, trimmedValue),
          );
        }}
      />
    );
  };

  function handleNumeric(value: any) {
    if (!value?.toString()) {
      return null;
    }

    return +value;
  }

  const renderTextArea = (fieldProps: FieldProps) => {
    return (
      <textarea
        {...fieldProps.field}
        placeholder={placeholder}
        onChange={(event) => {
          fieldProps.field.onChange(event.target.name)(event.target.value);
        }}
      />
    );
  };

  const renderLabel = () => {
    if (optional) {
      return (
        <LabelContainer>
          <label>{label}</label>
          <span> - {t("PositionFormModel.optional")}</span>
        </LabelContainer>
      );
    }

    if (important) {
      return (
        <LabelContainer>
          <label>{label}</label>
          <InfoButton
            name="infobutton"
            hasValue={!!value}
            content={
              !!value ? (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.fullfilled")}
                </>
              ) : (
                <>
                  <strong>
                    {t("PositionForm.InputRender.inputAdviseStrong")}
                  </strong>{" "}
                  {t("PositionForm.InputRender.inputAdvise")}
                </>
              )
            }
          />
        </LabelContainer>
      );
    }

    return <label>{label}</label>;
  };

  return (
    <FormInputContainer
      {...{ labelDistance, label }}
      error={error}
      disabled={disabled}
      height={height}
    >
      {label && (typeof label === "string" ? renderLabel() : label)}
      {loading ? (
        <div style={{ width: "100%" }}>
          <LoadingInput height={height} />
        </div>
      ) : (
        <Field name={name}>
          {(fieldProps: FieldProps) =>
            type === "textarea"
              ? renderTextArea(fieldProps)
              : renderInput(fieldProps, {
                  onlyPositive,
                  onlyInteger,
                  isActivator,
                })
          }
        </Field>
      )}
      {(error || errors?.[name]) && !noMessage && (
        <span>{error || errors?.[name]}</span>
      )}
      {observation && <label>{observation}</label>}
    </FormInputContainer>
  );
}
