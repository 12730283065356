import RSelect, { GroupBase, StylesConfig } from "react-select";
import { useTheme } from "styled-components";
import { OptionWithImageContainer, SelectContainer } from "./styles";
import { useTranslation } from "react-i18next";
import { LoadingInput } from "../LoadingInput";
import InformativeButton from "../InformativeButton";
import { useEffect } from "react";

interface IProps {
  value: any;
  handleChange: (e: any) => void;
  options: any[];
  disabled?: boolean;
  name?: string;
  styles?: StylesConfig<any, false, GroupBase<any>>;
  placeholder?: string;
  isSearchable?: boolean;
  components?: any;
  isClearable?: boolean;
  label?: string;
  displayImage?: boolean;
  error?: boolean;
  isLoading?: boolean;
  processing?: boolean;
  informativeButton?: string | JSX.Element;
}

export const Select = ({
  value,
  handleChange,
  options,
  disabled,
  name,
  styles,
  placeholder,
  isSearchable,
  isClearable,
  components,
  label,
  displayImage,
  error,
  isLoading,
  processing,
  informativeButton,
}: IProps) => {
  const { colors, fonts } = useTheme();

  const { t } = useTranslation();

  const defaultStyles: StylesConfig<any, false, GroupBase<any>> = {
    control: (base, props) => ({
      ...base,
      backgroundColor: colors.background,
      borderColor: error ? "red" : colors.cardHighlight,
      color: colors.iconsPrimary,
      height: "36px",
      marginTop: "4px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "transparent",
        outline: "none",
      },
      margin: 0,
      padding: 0,
      opacity: disabled ? "0.6" : "1",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
      color: colors.iconsPrimary,
    }),
    option: (base, props) => ({
      ...base,
      color: colors.iconsPrimary,
      ":hover": { backgroundColor: colors.headerBackground },
      ":active": { backgroundColor: colors.headerBackground },
      backgroundColor: `${props.isSelected && colors.cardBackground}`,
    }),
    valueContainer: (base) => ({
      ...base,
      color: colors.iconsPrimary,
      height: "100%",
      display: "flex",
      ...fonts.primary.H4Regular,
      position: "relative",
      "& > div": {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      [`input[type="text"]:focus`]: {
        boxShadow: "none",
      },
    }),
    input: (base) => ({
      ...base,
      position: "absolute",
      left: 0,
      border: "none",
      outline: "none",
    }),
    placeholder: (base) => ({
      ...base,
      // color: customColors?.placeholder?.color || colors.textPrimary,
      fontSize: "13px",
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.iconsPrimary,
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: colors.background,
      fontWeight: "600",
      fontSize: "12px",
      zIndex: 3,
    }),
  };

  const formatOption = (option: any) => {
    if (displayImage) {
      return (
        <OptionWithImageContainer>
          <img src={option.image} height={75} width={75} alt={option.image} />
          <label>{option.label}</label>
        </OptionWithImageContainer>
      );
    }

    return option.label;
  };

  return (
    <SelectContainer>
      {label && (
        <label>
          {label}
          {!!informativeButton && (
            <InformativeButton>{informativeButton}</InformativeButton>
          )}
        </label>
      )}
      {processing ? (
        <LoadingInput />
      ) : (
        <div data-test="select-dataTest">
        <RSelect<any>
          isSearchable={isSearchable ? true : false}
          value={value}
          onChange={handleChange}
          isClearable={isClearable}
          options={options}
          isDisabled={disabled}
          placeholder={placeholder}
          noOptionsMessage={() => `${t("users.noOptions")}`}
          components={components || {}}
          defaultValue={null}
          formatOptionLabel={(option) => formatOption(option)}
          styles={styles || defaultStyles}
          menuPlacement="auto"
          isLoading={isLoading}
        />
        </div>
      )}
    </SelectContainer>
  );
};
