import { RetinaMapsEmbbeded } from "../RetinaMapsEmbbeded";
import { useSegment } from "../../hooks/useSegmentTrackEvent ";
import {
  Selection,
  TabContainer,
  TabContent,
  TabNameDiv,
  TabName,
  TabIcon,
  TabDescription,
  TabErrorBall,
  EditableTabMenu,
  Triangle,
  OpenMenuContainer,
  LoadingContainer,
  TabHeaderContainer,
} from "./styles";
import { TabProps, TabsProps } from "./types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "../../utils/queryString";
import { MdAdd, MdClose } from "react-icons/md";
import useOnClickOutSide from "../../hooks/useOnClickOutside";
import { AnalyticsTab } from "../Analytics/AnalyticsTab";
import { EditableTab } from "../Analytics/EditableTab";
import { MosaicTab } from "../Analytics/MosaicTab";
import { useAppSelector } from "../../store/hooks";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import theme from "../../styles/themes/default";
import { ITab } from "../../pages/Facilities/types";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { appSelect } from "../../store/slices/appSlice";
import { NamePlusTooltip } from "../NamePlusTooltip";
import { AddNewTab } from "../Analytics/AddNewTab";
import { StandardAnalyticsMenu } from "../Analytics/StandardAnalyticsMenu";

export const Tabs = ({
  children,
  handleChangeTab,
  currentTab,
  adjustHeight,
  invisible,
  marginLeft,
  openEditableMenu,
  tabs,
  tabIcon,
  refresh,
  processing,
  maxContentWidth,
}: TabsProps) => {
  const params = useParams();
  const tabChildrens = Array.isArray(children) ? children : [children];
  const tabsControl = tabChildrens.map(({ key, props }) => ({
    key,
    label: props.label,
    hidden: props.hidden,
    icon: props.icon,
    errors: props.errors || 0,
  }));
  const selectedTab = currentTab || tabChildrens[0]?.key;
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const urlPattern = getUrlPattern(location, params);
  const user = useAppSelector(userSelect);
  const { isReadOnly } = useAppSelector(appSelect);
  const { CLIENTE_COMUM } = PROFILES;
  const isCommonClient = CLIENTE_COMUM === user.profile;

  const [showHopara, setShowHopara] = useState<boolean>(false);
  const [showHoparaConnectivity, setShowHoparaConnectivity] =
    useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState(false);

  useOnClickOutSide(ref, () => setOpenMenu(false));

  const queryParams = queryString.parse(location.search);

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const isConnectivity = useMemo(() => {
    if (queryParams?.sidetab && queryParams?.sidetab === "bolt") {
      return true;
    }
    return false;
  }, [queryParams]);

  useEffect(() => {
    if (currentTab === "MAPS" && !isConnectivity) {
      setShowHopara(true);
      setShowHoparaConnectivity(false);
    } else if (currentTab === "MAPS" && isConnectivity) {
      setShowHopara(false);
      setShowHoparaConnectivity(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const { handleTrackEvent } = useSegment();

  const selectPermissionOptions = {
    company: [
      { value: "user", label: `${t("Analytics.Apenas para mim")}` },
      {
        value: "company-facility",
        label: `${t("Analytics.Nível empresa e todas as unidades")}`,
      },
      { value: "company", label: `${t("Analytics.Apenas nível empresa")}` },
      { value: "facility", label: `${t("Analytics.Apenas nível unidade")}` },
    ],
    facility: [
      { value: "user", label: `${t("Analytics.Apenas para mim")}` },
      {
        value: "company-facility",
        label: `${t("Analytics.Nível empresa e todas as unidades")}`,
      },
      { value: "facility", label: `${t("Analytics.Apenas nível unidade")}` },
    ],
    asset: [{ value: "asset", label: `${t("Analytics.Apenas nível ativo")}` }],
  };

  const returnPermissionOptions = () => {
    const filteredOptions = (options: any) => {
      if (isCommonClient) {
        return options.filter((option: any) => option.value === "user");
      }
      return options;
    };
    if (params.assetId) {
      return filteredOptions(selectPermissionOptions["asset"]);
    }

    if (params.facilityId) {
      return filteredOptions(selectPermissionOptions["facility"]);
    }
    if (params.companyId) {
      return filteredOptions(selectPermissionOptions["company"]);
    }
  };

  const clearTabNavigation = (tabsRemoved: any) => {
    const tabsKeyRemoved = tabsRemoved.map((removedTab: any) => {
      return removedTab?.key;
    });

    const availableTabs = tabChildrens.map((tab: any) => {
      return tab?.key;
    });

    const remainingTabKeys = availableTabs.filter(
      (key: any) => !tabsKeyRemoved.includes(key),
    );

    if (
      !remainingTabKeys.includes(currentTab) &&
      remainingTabKeys.length &&
      handleChangeTab
    ) {
      handleChangeTab(remainingTabKeys[0]);
    }
  };

  const page = useMemo(() => {
    if (urlPattern === ROUTES_PATH.FACILITIES) {
      return "facilities";
    }

    if (urlPattern === ROUTES_PATH.ASSETS) {
      return "assets";
    }

    if (urlPattern === ROUTES_PATH.ASSET) {
      return "asset";
    }

    return "companies";
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!tabs?.length) return;

    const availableTabs: any[] = tabs
      ?.filter((item: ITab) => item.key && !item.hidden)
      .map((tab: ITab) => {
        return tab.key.toLowerCase();
      });

    const localStorageTab = JSON.parse(
      localStorage.getItem("selectedTabs") || "{}",
    );

    const lastSelectedTab = localStorageTab[page];

    if (availableTabs?.includes(lastSelectedTab) && !queryParams.tab) {
      return;
    }

    if (
      (!availableTabs?.includes(queryParams.tab) && availableTabs?.length) ||
      !lastSelectedTab
    ) {
      handleChangeTab && handleChangeTab(availableTabs[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs?.length, queryParams.tab]);

  const isDevelopment = process.env.REACT_APP_ENV === "development";

  return (
    <>
      {!invisible && (
        <TabHeaderContainer>
          <TabContainer openEditable={openEditableMenu}>
            {tabsControl.map(
              (item) =>
                !item.hidden && (
                  <TabNameDiv
                    marginLeft={marginLeft || "16px"}
                    key={item?.key}
                    onClick={() => {
                      if (handleChangeTab) {
                        handleChangeTab(item?.key as string);
                      }
                      handleTrackEvent({
                        event: `Tab ${item?.key}`,
                      });
                    }}
                  >
                    <TabDescription selected={item?.key === selectedTab}>
                      <TabIcon>{item.icon}</TabIcon>
                      <NamePlusTooltip
                        tooltip={
                          item.label.length >= 21 &&
                          maxContentWidth &&
                          item.label
                        }
                        disabled={item.label.length <= 21 || !maxContentWidth}
                        position={"top"}
                      >
                        <TabName
                          selected={item?.key === selectedTab}
                          maxContentWidth={maxContentWidth}
                        >
                          <span>{item.label}</span>
                        </TabName>
                      </NamePlusTooltip>
                    </TabDescription>
                    <Selection selected={item?.key === selectedTab} />
                    {item.errors ? (
                      <TabErrorBall>{item.errors}</TabErrorBall>
                    ) : (
                      <></>
                    )}
                  </TabNameDiv>
                ),
            )}

            {processing && (
              <LoadingContainer>
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: theme.colors.iconsSecondary }}
                />
              </LoadingContainer>
            )}
          </TabContainer>
          {openEditableMenu && (
            <OpenMenuContainer openedMenu={openMenu} ref={ref}>
              <button onClick={() => setOpenMenu(!openMenu)}>
                {openMenu ? <MdClose /> : <MdAdd />}
              </button>
              {openMenu && !isTablet && !isMobileOnly && (
                <>
                  <Triangle />
                  <EditableTabMenu>
                    <EditableTab
                      tabs={tabs}
                      icon={tabIcon}
                      selectPermissionOptions={returnPermissionOptions()}
                      refresh={refresh}
                      blocked={isReadOnly}
                      clearNavigate={clearTabNavigation}
                      onCloseMenu={handleCloseMenu}
                    />
                    {isDevelopment ? (
                      <AddNewTab
                        selectPermissionOptions={returnPermissionOptions()}
                        refresh={refresh}
                        blocked={isReadOnly}
                        onCloseMenu={handleCloseMenu}
                      />
                    ) : (
                      <>
                        <StandardAnalyticsMenu
                          menuLabel={t("Analytics.Adicionar Analytics") || ""}
                          title={t("Analytics.Adicionar Novo Analytics")}
                        >
                          <AnalyticsTab
                            selectPermissionOptions={returnPermissionOptions()}
                            refresh={refresh}
                            blocked={isReadOnly}
                            onCloseMenu={handleCloseMenu}
                          />
                        </StandardAnalyticsMenu>
                        <StandardAnalyticsMenu
                          menuLabel={t("Analytics.Adicionar Mosaico") || ""}
                          title={t("Analytics.Adicionar Mosaico")}
                        >
                          <MosaicTab
                            refresh={refresh}
                            selectPermissionOptions={returnPermissionOptions()}
                            blocked={isReadOnly}
                            onCloseMenu={handleCloseMenu}
                          />
                        </StandardAnalyticsMenu>
                      </>
                    )}
                  </EditableTabMenu>
                </>
              )}
            </OpenMenuContainer>
          )}
        </TabHeaderContainer>
      )}

      <TabContent adjustHeight={adjustHeight || "70%"} isTablet={isTablet}>
        {selectedTab !== "MAPS" &&
          tabChildrens.map((tab) => tab?.key === selectedTab && tab)}
        {showHopara && !isConnectivity && (
          <RetinaMapsEmbbeded
            hide={selectedTab !== "MAPS" || !tabChildrens.length}
          />
        )}
        {showHoparaConnectivity && isConnectivity && (
          <RetinaMapsEmbbeded
            hide={selectedTab !== "MAPS"}
            isConnectivity={true}
          />
        )}
      </TabContent>
    </>
  );
};

export const Tab = ({ children }: TabProps) => {
  return children;
};
