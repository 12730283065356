import { useEffect, useState, useCallback, useRef } from "react";
import CountUp from "react-countup";

import useApi from "../../../hooks/useApi";
import { Loading } from "../../../components/Loading";

import {
  Container,
  Content,
  Data,
  InfoButtonContainer,
  InfoContainer,
  Separator,
  Title,
  Triangle,
} from "./styles";
import { MdDoubleArrow } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import queryString from "../../../utils/queryString";
// import { CardSynchronizedSensorsProps } from "./types";

interface IProps {
  facilityId: any;
}

export const CardSynchronizedSensors = ({ facilityId }: IProps) => {
  const [showInfo, setShowInfo] = useState(false);
  const { t } = useTranslation();

  const optionsRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { request, processing } = useApi({
    path: `/gateways-positions-status`,
  });

  const [activatedPositions, setActivatedPositions] = useState(0);
  const [synchronizedPositions, setSynchronizedPositions] = useState(0);
  const [connectedPositions, setConnectedPositions] = useState(0);

  const params = useParams();
  const location = useLocation();

  const fetchData = useCallback(
    ({ noProcessing }: { noProcessing: boolean }) => {
      const queryParams = queryString.parse(location.search);
      const queryStringParameters = {
        ...params,
        facilityId,
        gatewayId: queryParams.boltId,
      };
      request({
        method: "get",
        queryStringParameters,
        noProcessing,
        skipToast: true,
      }).then((response) => {
        setActivatedPositions(+response?.activatedPositions || 0);
        setConnectedPositions(+response?.connectedPositions || 0);
        setSynchronizedPositions(+response?.synchronizedPositions || 0);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facilityId, location.search],
  );

  useEffect(() => {
    fetchData({ noProcessing: false });

    const interval = setInterval(
      () => fetchData({ noProcessing: true }),
      15 * 1000,
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId, location.search]);

  // click outside
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as HTMLElement) &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target as HTMLElement)
      ) {
        setShowInfo(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <Container id="connected">
      <Title>
        <span>{t("cardSynchronizedSensors.title")}</span>
        <InfoButtonContainer
          ref={optionsRef}
          onClick={() => setShowInfo(!showInfo)}
        >
          <IoMdInformationCircleOutline size={24} />
        </InfoButtonContainer>
      </Title>
      {showInfo ? (
        <>
          <Triangle left={optionsRef.current?.offsetLeft}></Triangle>
          <InfoContainer ref={menuRef} left={optionsRef.current?.offsetLeft}>
            <span>
              {t("cardSynchronizedSensors.Indica que")}{" "}
              {+activatedPositions > 1
                ? `${t("cardSynchronizedSensors.dos")}`
                : `${t("cardSynchronizedSensors.de")}`}{" "}
              <strong>{activatedPositions}</strong>{" "}
              {t("cardSynchronizedSensors.ponto")}
              {+activatedPositions > 1 && "s "}{" "}
              {t("cardSynchronizedSensors.ativado")}
              {+activatedPositions > 1 && "s "},{" "}
              {+synchronizedPositions > 0 && (
                <strong>{synchronizedPositions}</strong>
              )}{" "}
              {+synchronizedPositions === 0 &&
                t("cardSynchronizedSensors.nenhum")}{" "}
              {t("cardSynchronizedSensors.ponto")}
              {+synchronizedPositions > 1 && "s "}{" "}
              {+synchronizedPositions > 1
                ? `${t("cardSynchronizedSensors.estão")}`
                : `${t("cardSynchronizedSensors.está")}`}{" "}
              {+synchronizedPositions <= 1 &&
                t("cardSynchronizedSensors.sincronizado")}
              {+synchronizedPositions > 1 &&
                t("cardSynchronizedSensors.sincronizados")}{" "}
              {t("cardSynchronizedSensors.com o Bolt e transmitindo coletas.")}
            </span>
          </InfoContainer>
        </>
      ) : null}
      <Content>
        {processing ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading marginTop="0" />
          </div>
        ) : (
          <>
            <Data>
              <span>
                <CountUp
                  start={activatedPositions || 0}
                  end={activatedPositions}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>{t("cardSynchronizedSensors.activated")}</label>
            </Data>
            <Separator>
              <MdDoubleArrow />
            </Separator>
            <Data>
              <span>
                <CountUp
                  start={synchronizedPositions || 0}
                  end={synchronizedPositions}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>
                {isMobileOnly
                  ? "Sync."
                  : t("cardSynchronizedSensors.synchronized")}
              </label>
            </Data>
            <Separator delay>
              <MdDoubleArrow />
            </Separator>
            <Data>
              <span>
                <CountUp
                  start={connectedPositions || 0}
                  end={connectedPositions}
                  duration={0.8}
                  separator={"."}
                />
              </span>
              <label>
                {isMobileOnly
                  ? "Conect."
                  : t("cardSynchronizedSensors.connected")}
              </label>
            </Data>
          </>
        )}
      </Content>
    </Container>
  );
};
