import { useEffect, useMemo, useRef, useState } from "react";
import ModalCompound from "../../components/ModalCompound";
import { Formik, FormikProps } from "formik";
import { DiagnosticModel, appSelect } from "../../store/slices/appSlice";
import {
  ContainerOccurreceForm,
  NotOccurrenceCustomField,
  OccurrenceCustomFieldContainer,
  OccurrenceCustomFieldTitle,
} from "./styles";
import { useTranslation } from "react-i18next";
import FormSelect from "../../components/FormSelect";
import { FormikTextInput } from "../../components/textInput";
import useApi from "../../hooks/useApi";
import useOccurrenceForm from "./useOccurrenceForm";
import InputList from "../../components/InputList";
import useRetinaTranslation from "../../hooks/useRetinaTranslation";
import { useAppSelector } from "../../store/hooks";
import { PROFILES, userSelect } from "../../store/slices/userSlice";
import { LoadingInput } from "../../components/LoadingInput";
import { FormInputContainer } from "../../components/textInput/styles";
import { Link } from "react-router-dom";

interface IOccurrenceForm {
  show: boolean;
  refresh?: () => void;
  contract?: any;
  isEdit?: boolean;
  occurrenceData?: any;
}
export default function OccurrenceForm({
  show,
  refresh = () => null,
  isEdit,
  occurrenceData,
}: IOccurrenceForm) {
  const { contract, occurrenceRegister } = useAppSelector(appSelect);
  const user = useAppSelector(userSelect);
  const formRef = useRef<FormikProps<any>>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (occurrenceData) {
      formRef?.current?.setValues(occurrenceData);
    }
  }, [occurrenceData]);

  const [sysDiagnostics, setSysDiagnostics] = useState<any[]>([]);

  const { translateDiagnostic, translateSugestion } = useRetinaTranslation();

  const {
    disabledChangeStatus,
    sysStatusId,
    refresh: refreshOccurrenceForm,
  } = occurrenceRegister;

  const refreshList = () => {
    if (refreshOccurrenceForm) {
      refreshOccurrenceForm();
    }

    refresh();
  };

  const { handleClose, occurrence, onSubmit, validate, processingOccurrence } =
    useOccurrenceForm({
      formRef,
      refresh: refreshList,
    });

  const facilityContract = occurrenceRegister.contract || contract;

  const userAdmin = [
    PROFILES.EXECUTIVO_MASTER,
    PROFILES.MESA_MASTER,
    PROFILES.MESA_ANALISTA,
    PROFILES.REPRESENTANTE,
  ].includes(user?.profile ?? "");

  useEffect(() => {
    loadDiagnosticsOptions();
    // eslint-disable-next-line
  }, []);

  const translatedOptions_Status: any = [
    {
      label: t("dictionary.status.Normal"),
      value: 1,
    },
    {
      label: t("dictionary.status.Alert"),
      value: 3,
    },
    {
      label: t("dictionary.status.Risk"),
      value: occurrence?.sysStatusId === 6 ? 6 : 5,
    },
    {
      label: t("dictionary.status.Critical"),
      value: 7,
    },
  ];

  const categoryOptions = [
    {
      label: `${t("OccurrenceForm.Inspeção Sensitiva")}`,
      value: "SENSITIVE_INSPECTION",
    },
    { label: `${t("OccurrenceForm.Coleta Manual")}`, value: "MANUAL_COLLECT" },
    { label: `${t("OccurrenceForm.Outros")}`, value: "OTHERS" },
  ];

  const { request } = useApi({ path: "" });

  function loadDiagnosticsOptions() {
    request({ method: "get", pathParameters: "diagnostics" }).then(
      setSysDiagnostics,
    );
  }

  const occurrenceCustomFields = isEdit
    ? formRef?.current?.values?.occurrenceCustomFields
    : occurrenceRegister?.occurrenceCustomFields;

  const facilityId = isEdit
    ? formRef?.current?.values?.facilityId
    : occurrenceRegister.facilityId;

  const companyId = isEdit
    ? formRef?.current?.values?.companyId
    : occurrenceRegister.companyId;

  const diagnosticOptions = useMemo(() => {
    return sysDiagnostics.map((item: DiagnosticModel) => ({
      value: item.name,
      label: translateDiagnostic(item.name),
      recommendation: translateSugestion(item.sugestion),
    }));
    // eslint-disable-next-line
  }, [sysDiagnostics]);

  function buildDiagnostic(diagnostic: any, curIndex: number) {
    function handleDelete() {
      let diagnostics = formRef.current?.values.diagnostics ?? [];

      if (diagnostic.id) {
        diagnostics = diagnostics.map((item: any, i: number) => {
          if (item.id === diagnostic.id) {
            item.deleted = true;
          }
          return item;
        });
      } else {
        diagnostics = diagnostics.filter((_: any, i: number) => i !== curIndex);
      }

      formRef.current?.setFieldValue("diagnostics", diagnostics);
    }

    const name = `diagnostics[${curIndex}]`;

    function handleDiagnotic({ value, recommendation }: any) {
      formRef.current?.setFieldValue(`${name}[diagnostic]`, value);
      formRef.current?.setFieldValue(
        `${name}[recommendation]`,
        recommendation || "",
      );
    }

    return (
      <InputList.Column hidden={diagnostic.deleted}>
        <InputList.Row position="relative">
          <FormSelect
            disabled={!!diagnostic.disabled}
            name={`${name}[diagnostic]`}
            handleChange={handleDiagnotic}
            label={t("OccurrenceForm.diagnostic")}
            options={diagnosticOptions}
            loading={processingOccurrence}
          />
          <FormikTextInput
            disabled={!!diagnostic.disabled}
            name={`${name}[recommendation]`}
            label={t("OccurrenceForm.recommendation")}
            loading={processingOccurrence}
          />
          <InputList.Delete handleClick={handleDelete} />
        </InputList.Row>
        <InputList.Row>
          <FormikTextInput
            disabled={!!diagnostic.disabled}
            name={`${name}[comments]`}
            label={t("OccurrenceForm.comments")}
            type="textarea"
            height="200px"
            loading={processingOccurrence}
          />
        </InputList.Row>
      </InputList.Column>
    );
  }

  interface FieldType {
    id: number;
    key: string;
    value: string;
    units: string[];
    order?: number;
  }

  const filterFields = (fields: FieldType[], facilityId: string) => {
    return fields
      ?.filter(
        (field) =>
          field.units?.includes("all") || field.units?.includes(facilityId),
      )
      ?.sort((a, b) => (a.order && b.order ? a.order - b.order : 0))
      ?.reverse();
  };

  const mapExtraFields = (
    sortedFields: FieldType[],
    extraFields: FieldType[],
  ) => {
    return sortedFields
      ?.map((ocf) => {
        const found = extraFields.find((eocf) => eocf.id === ocf.id);
        return found || { id: ocf.id, value: "", key: ocf.key };
      })
      ?.filter(Boolean);
  };

  return (
    <ModalCompound show={show} size="xl" backdrop="static">
      <ModalCompound.Body>
        <Formik
          initialValues={{
            sysStatusId: sysStatusId || 3,
            diagnostics: [{ diagnostic: "", recommendation: "", comments: "" }],
            category: "OTHERS",
            extra: {
              occurrenceCustomFields: (isEdit
                ? formRef?.current?.values?.occurrenceCustomFields
                : occurrenceRegister?.occurrenceCustomFields || []
              )
                ?.filter(
                  (field: any) =>
                    field.units?.includes("all") ||
                    field.units?.includes(facilityId),
                )
                ?.map((field: any) => {
                  return { id: field.id, value: "", key: field.key };
                }),
            },
          }}
          validate={validate}
          onSubmit={onSubmit}
          innerRef={formRef}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <ContainerOccurreceForm>
            <h1>{t(`OccurrenceForm.title.${isEdit ? "edit" : "create"}`)}</h1>
            <div>
              <FormSelect
                loading={processingOccurrence}
                options={translatedOptions_Status}
                label="Status"
                name="sysStatusId"
                handleChange={(value) => {
                  formRef.current?.setFieldValue("sysStatusId", value?.value);
                }}
                notClearable
                disabled={
                  disabledChangeStatus ||
                  (occurrence &&
                    !occurrence?.manuallyOpened &&
                    facilityContract === "PaaS")
                }
                title={
                  !occurrence ||
                  (!!occurrence?.manuallyOpened && contract === "PaaS")
                    ? `${t(
                        "OccurrenceForm.automaticOccurrenceStatusNotEditable",
                      )}`
                    : ""
                }
              />

              {facilityContract === "SOLaaS" && userAdmin && (
                <FormSelect
                  loading={processingOccurrence}
                  options={categoryOptions}
                  label={t("OccurrenceForm.title.categoria")}
                  name="category"
                  handleChange={(value) => {
                    formRef.current?.setFieldValue(
                      "category",
                      value?.value ?? null,
                    );
                  }}
                  notClearable
                />
              )}

              <FormikTextInput
                label={t("OccurrenceForm.daysToAction")}
                name="days"
                type="number"
                onlyPositive
                loading={processingOccurrence}
              />
            </div>
            <div>
              <InputList
                name="diagnostics"
                label={`${t("OccurrenceForm.diagnostics")}`}
                empty={{ diagnostic: "", recommendation: "", comments: "" }}
                neverEmpty
                build={buildDiagnostic}
              />
            </div>
            <>
              <OccurrenceCustomFieldTitle>
                {t("OccurrenceForm.customFields")}
              </OccurrenceCustomFieldTitle>

              <div style={{ width: "100%" }}>
                {processingOccurrence && !occurrenceCustomFields && (
                  <FormInputContainer>
                    <LoadingInput height="34px" />
                  </FormInputContainer>
                )}
              </div>

              {!processingOccurrence &&
                (occurrenceCustomFields || [])?.filter(
                  (field: any) =>
                    field.units?.includes("all") ||
                    field.units?.includes(facilityId),
                ).length === 0 && (
                  <NotOccurrenceCustomField>
                    <span>
                      {t("OccurrenceForm.noCustomFields")}
                      <Link
                        to={`/configuration/${companyId}/details?tab=standard`}
                      >
                        {t("OccurrenceForm.addCustomField")}
                      </Link>
                    </span>
                  </NotOccurrenceCustomField>
                )}

              <OccurrenceCustomFieldContainer>
                {occurrenceCustomFields &&
                  (() => {
                    const sortedOccurrenceCustomFields = filterFields(
                      occurrenceCustomFields,
                      facilityId,
                    );
                    const extraOccurrenceCustomFields =
                      formRef.current?.values?.extra?.occurrenceCustomFields ||
                      [];
                    const sortedExtraOccurrenceCustomFields = mapExtraFields(
                      sortedOccurrenceCustomFields,
                      extraOccurrenceCustomFields,
                    );

                    formRef.current?.setFieldValue(
                      "extra[occurrenceCustomFields]",
                      sortedExtraOccurrenceCustomFields,
                    );

                    return (sortedOccurrenceCustomFields || [])?.map(
                      (field, index) => (
                        <FormikTextInput
                          label={field.key}
                          type="text"
                          name={`extra[occurrenceCustomFields][${index}][value]`}
                          key={field.id}
                          loading={processingOccurrence}
                        />
                      ),
                    );
                  })()}
              </OccurrenceCustomFieldContainer>
            </>
          </ContainerOccurreceForm>
        </Formik>
      </ModalCompound.Body>
      <ModalCompound.Footer>
        <ModalCompound.Cancel handleClose={handleClose} />
        <ModalCompound.Save
          handleConfirm={() => formRef.current?.handleSubmit()}
        />
      </ModalCompound.Footer>
    </ModalCompound>
  );
}
