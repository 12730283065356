import React, { useMemo, useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import {
  ButtonSelect,
  ContainerMobile,
  ExpandedDashboard,
  FrameDashboard,
  FrameDashboardBox,
  FrameDashboardBoxMobile,
  HideDashboardButton,
} from "./styles";
import { MdOutlineEdit } from "react-icons/md";
import { NamePlusTooltip } from "../NamePlusTooltip";
import { EditMarqee } from "../../modals/EditMarqee";

import { useLocation, useParams } from "react-router-dom";
import { getUrlPattern } from "../../utils/getUrlPattern";
import { ROUTES_PATH } from "../../routes/RoutesPath";
import { FaChevronDown } from "react-icons/fa";

import { setHideDashboardCharts } from "../../store/slices/dashboardToggleSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import useDashboard from "../../hooks/useDashboard";
import Marquee from "react-fast-marquee";
import { MarqueeContainer } from "../RenderBetweenCarousel/styles";
import { useTheme } from "styled-components";
import { CgArrowsExpandLeft } from "react-icons/cg";
import { setShowSidebar } from "../../store/slices/sidebarSlice";
import { isMobileOnly } from "react-device-detect";
import { IAsset } from "../../pages/Asset/types";
import { useAppSelector } from "../../store/hooks";
import {
  setUserDashboardsPreferences,
  userSelect,
} from "../../store/slices/userSlice";
import useApi from "../../hooks/useApi";
import { success, error as errorToast } from "../Toast";
import { appSelect } from "../../store/slices/appSlice";

type Props = {
  children: any;
  isOccurrence?: boolean;
  isBoltNewArchiteture?: boolean;
  isBoltTab?: boolean;
  assetDetails?: IAsset;
};

export function Dashboard({
  children,
  isOccurrence,
  isBoltNewArchiteture,
  isBoltTab,
}: Props) {
  const { refreshDashboards } = useAppSelector(appSelect);
  const { theme } = useTheme();
  const location = useLocation();
  const params = useParams();
  const urlPattern = getUrlPattern(location, params);

  const user = useAppSelector(userSelect);

  const { request, processing } = useApi({
    path: `/users/${user.id}/dashboards-preferences`,
  });

  const { dashboardsPreference } = user;

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const { loadPageDashboards } = useDashboard();

  useEffect(() => {
    if (urlPattern !== ROUTES_PATH.OCCURRENCES) {
      loadPageDashboards();
      const interval = setInterval(() => {
        loadPageDashboards();
      }, 10 * 60 * 1000);

      return () => {
        clearInterval(interval);
      };
    }

    // eslint-disable-next-line
  }, [refreshDashboards]);

  const hideDashboardCharts = useSelector(
    (state: RootState) => state.dashboardToggle.hideDashboardCharts,
  );

  const page = useMemo(() => {
    if (urlPattern === ROUTES_PATH.FACILITIES) {
      return "facilities";
    }

    if (urlPattern === ROUTES_PATH.ASSETS) {
      return "assets";
    }

    if (urlPattern === ROUTES_PATH.ASSET) {
      return "asset";
    }

    return "companies";
    // eslint-disable-next-line
  }, [location]);

  const [dashboardsList, setDashboardsList] = useState<any>();

  useEffect(() => {
    setDashboardsList(dashboardsPreference);
  }, [dashboardsPreference]);

  const childrenComponents = React.Children.map(children, (child) => {
    return child?.props?.children;
  });

  const dashboardCards = childrenComponents.map((item: any) => {
    return item?.props?.id;
  });

  const showSidebar = useSelector(
    (state: RootState) => state.sidebar.showSidebar,
  );

  const handleExpandedDashboard = () => {
    if (showSidebar === true && hideDashboardCharts === true) {
      dispatch(setHideDashboardCharts(false));
      dispatch(setShowSidebar(false));
    } else if (showSidebar === true && hideDashboardCharts === false) {
      dispatch(setHideDashboardCharts(false));
      dispatch(setShowSidebar(false));
    } else if (showSidebar === false && hideDashboardCharts === true) {
      dispatch(setHideDashboardCharts(false));
      dispatch(setShowSidebar(false));
    } else {
      dispatch(setHideDashboardCharts(!hideDashboardCharts));
      dispatch(setShowSidebar(!showSidebar));
    }
  };

  const handleClickEditChildren = () => {
    setShowModal(true);
  };

  const handleCancelEdition = () => {
    setDashboardsList(dashboardsPreference);
    setShowModal(false);
  };

  const editHandleClick = (item: string, value: boolean) => {
    setDashboardsList({
      ...dashboardsList,
      [page]: {
        ...dashboardsList[page],
        [item]: value,
      },
    });
  };

  const handleConfirmEdit = async () => {
    if (!processing) {
      await request({ method: "put", body: dashboardsList })
        .then((response) => {
          success("Alterações salvas com sucesso!");
          setDashboardsList(response?.dashboardsPreference);
          dispatch(
            setUserDashboardsPreferences(response?.dashboardsPreference),
          );
        })
        .catch(() => {
          errorToast("Erro ao salvar alterações");
        });
    }
  };

  if (isMobileOnly && isBoltNewArchiteture) {
    return (
      <ContainerMobile>
        {children.length > 0 ? (
          children.map((item: any, i: number) => (
            <FrameDashboardBoxMobile key={i}>{item}</FrameDashboardBoxMobile>
          ))
        ) : (
          <FrameDashboardBoxMobile>{children}</FrameDashboardBoxMobile>
        )}
      </ContainerMobile>
    );
  }

  function renderChildrens() {
    const childrens = children.props.children.filter((child: any) => {
      const { id } = child?.props || {};
      return dashboardsList?.[page] && dashboardsList?.[page][id];
    });

    if (childrens.length === 1) {
      return (
        <Marquee
          pauseOnHover={true}
          speed={0}
          style={{ zIndex: "1", gap: "8px", display: "flex", overflow: "hidden" }}
          gradientColor={theme === "dark" ? [11, 13, 18] : [227, 232, 238]}
          gradientWidth={70}
          delay={2}
          play={false}
          key={1000}
        >
          <MarqueeContainer>{childrens[0]}</MarqueeContainer>
        </Marquee>
      );
    }

    return {
      ...children,
      props: {
        ...children.props,
        children: childrens,
      },
    };
  }

  if (isMobileOnly) {
    return (
      <Carousel
        style={{
          padding: "0 .5rem",
          marginTop: "3rem",
          zIndex: "0",
        }}
      >
        {children.length > 0 ? (
          children.map((item: any, i: number) => (
            <Carousel.Item key={i}>
              <FrameDashboardBox>{item}</FrameDashboardBox>
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item>
            <FrameDashboardBox>{children}</FrameDashboardBox>
          </Carousel.Item>
        )}
      </Carousel>
    );
  } else {
    return (
      <>
        <FrameDashboard
          isOccurrence={isOccurrence}
          hideDashboardCharts={hideDashboardCharts}
          getTheme={theme}
          isBoltTab={isBoltTab}
        >
          {!isBoltTab && (
            <NamePlusTooltip
              position={"left"}
              tooltip={t("dictionary.actions.Edit Cards")}
            >
              <ButtonSelect
                onClick={handleClickEditChildren}
                hideDashboardCharts={hideDashboardCharts}
              >
                <MdOutlineEdit size={22} />
              </ButtonSelect>
            </NamePlusTooltip>
          )}
          {children.length > 0 ? (
            <>
              {children.map((item: any, i: number) => (
                <FrameDashboardBox key={i}>{item}</FrameDashboardBox>
              ))}
            </>
          ) : (
            <FrameDashboardBox>{renderChildrens()}</FrameDashboardBox>
          )}
          {showModal && (
            <EditMarqee
              show={showModal}
              handleClose={() => setShowModal(false)}
              handleCancel={handleCancelEdition}
              handleClick={editHandleClick}
              handleConfirmEdit={handleConfirmEdit}
              listDashboardCards={dashboardCards}
              dashboardCards={dashboardsList[page]}
            />
          )}
        </FrameDashboard>
        <HideDashboardButton
          onClick={() => {
            dispatch(setHideDashboardCharts(!hideDashboardCharts));
          }}
          expandDashboard={hideDashboardCharts}
        >
          <FaChevronDown />
        </HideDashboardButton>
        {!isMobileOnly && (
          <ExpandedDashboard
            onClick={() => handleExpandedDashboard()}
            title={
              showSidebar
                ? `${t("dictionary.actions.Expand")}`
                : `${t("dictionary.actions.Collapse")}`
            }
          >
            <CgArrowsExpandLeft />
          </ExpandedDashboard>
        )}
      </>
    );
  }
}
