import { isMobileOnly } from "react-device-detect";
import { DrawerComponent } from "../../DrawerComponent";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import { ReactNode, useState } from "react";
import { AnalyticsComponent } from "../AnalyticsComponent";

interface IStandardAnalyticsMenu {
  children: ReactNode;
  menuLabel: string;
  title: string;
}

export function StandardAnalyticsMenu({
  children,
  title,
  menuLabel,
}: IStandardAnalyticsMenu) {
  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer((prevS) => !prevS);
  };

  return (
    <Container>
      <DrawerComponent
        label={menuLabel}
        direction="right"
        isOpen={openDrawer}
        handleOpen={toggleDrawer}
        handleClose={toggleDrawer}
        size={isMobileOnly ? 350 : 600}
      >
        <AnalyticsComponent>
          <AnalyticsComponent.Title title={title} />
          <AnalyticsComponent.Beta />

          {children}
        </AnalyticsComponent>
      </DrawerComponent>
    </Container>
  );
}
