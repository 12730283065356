import styled from "styled-components";

export const InputListContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  background-color: ${(props) => props.theme.colors.BackgroundShadow};

  & > label {
    ${(props) => props.theme.fonts.primary.H4Regular};
    color: ${(props) => props.theme.colors.iconsSecondary};
  }

  & > svg {
    font-size: 20px;
    width: 36px;
    height: 36px;
    padding: 3px;
    color: ${(props) => props.theme.colors.iconsPrimary};
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    &:hover {
      background-color: ${(props) => props.theme.colors.cardHighlight};
    }
  }

  hr {
    margin: 4px 0;
  }

  & > div {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    & > div {
      & > div:first-child {
        & > div {
          & > div {
          }
        }
      }
    }
  }
  @media screen and (orientation: portrait) and (min-width: 767px) and (max-width: 1023px) {
    /* border: 1px solid gold;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;

    & > div {      
      & > div:nth-child(1) {
        width: 100%;
        border: 1px solid red;
      }
      & > div:nth-child(2) {
        border: 1px solid red;
        width: 100%;
      }
      & > div:nth-child(3) {
        border: 1px solid red;
        width: 100%;
      }
    } */

    & > div {
      & > div:nth-child(1) {
        width: 100%;
        margin-bottom: 0;
        padding: 0;

        label {
          margin-bottom: 0;
        }
        select {
          font-size: 0.8rem;
        }

        & > div {
          margin-bottom: 0;
          padding: 0;
        }
      }
    }
  }
`;

export const EmptyField = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #717a9b;
  margin-top: 16px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.theme.fonts.primary.H4Regular};

    & > svg {
      font-size: 20px;
      width: 16px;
      height: 16px;
      padding: 1px;
      margin: 4px;
      color: ${(props) => props.theme.colors.iconsPrimary};
      cursor: pointer;
      border-radius: 3px;
      border: 1px solid ${(props) => props.theme.colors.cardHighlight};
      &:hover {
        background-color: ${(props) => props.theme.colors.cardHighlight};
      }
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  & > label {
    color: ${(props) => props.theme.colors.iconsPrimary};
    ${(props) => props.theme.fonts.primary.H4Regular};
  }

  & > svg {
    background: ${(props) => props.theme.colors.ibbxOrange};
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid transparent;
    ${(props) => props.theme.fonts.primary.H4Regular};
    text-decoration: none;

    cursor: pointer;

    &:hover {
      filter: brightness(1.2);
      border: 1px solid ${(props) => props.theme.colors.lightOrange};
    }
    transition: all 0.3s ease;
  }
`;

interface IInputListDeleteItem {
  hoverLabel?: string;
}

export const InputListDeleteItem = styled.button<IInputListDeleteItem>`
  display: flex;
  justify-content: center !important;
  align-items: center;
  border-radius: 50px;
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${({ theme }) => theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: none;
  position: relative;
  margin-bottom: 0.28rem;

  svg {
    font-size: 1rem;
  }

  :active {
    transform: scale(0.95);
    transition: 0.2s;
  }

  @keyframes button-on {
    0% {
      transform: rotateY(70deg);
      transform-origin: right;
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      transform-origin: right;
      opacity: 1;
    }
  }

  :hover:after {
    content: ${({ hoverLabel = "Excluir " }) => `"${hoverLabel}"`};
    ${(props) => props.theme.fonts.primary.H5SemiBold};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 1.75rem;
    right: 0.25rem;
    top: 0px;
    background-color: ${({ theme }) => theme.colors.cardHighlight};
    border-radius: 16px;
    animation: button-on 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }
`;

interface IInputListRow {
  position?: "relative";
  hidden?: boolean;
}

export const InputListRow = styled.div<IInputListRow>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;

  gap: 8px;
  position: ${({ position }) => position && position};
  display: ${({ hidden }) => hidden && "none"};
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 0.2rem;

  & > div {
    flex: 1;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.cardHighlight};
    transition: all 0.3s easy;
  }
`;

export const InputListColumn = styled(InputListRow)`
  flex-direction: column;
`;
